<template>
    <div class="user-details">
        <CabinetHeader />
        <b-form>
            <b-row class="m-0">
                <b-col md="3">
                    <br />
                    <b-form-group label="Фамилия">
                        <div class="input-with-icon">
                            <b-form-input
                                id="input-1"
                                v-model="lastName"
                                type="text"
                                :readonly="!isEditingInput1"
                                :class="{
                                    editable: isEditingInput1,
                                    readonly: !isEditingInput1,
                                }"
                                placeholder=""
                                class="input-with-icon"
                            ></b-form-input>

                            <i
                                class="icon icon-pencil-edit"
                                v-if="showEditIcon1"
                                @click="toggleEditingLastName"
                            ></i>
                        </div>
                    </b-form-group>
                    <b-form-group label="Имя">
                        <div class="input-with-icon">
                            <b-form-input
                                id="input-2"
                                v-model="firstName"
                                type="text"
                                :readonly="!isEditingInput2"
                                :class="{
                                    editable: isEditingInput2,
                                    readonly: !isEditingInput2,
                                }"
                                placeholder=""
                                class="input-with-icon"
                            ></b-form-input>

                            <i
                                class="icon icon-pencil-edit"
                                v-if="showEditIcon2"
                                @click="toggleEditingFirstName"
                            ></i>
                        </div>
                    </b-form-group>
                    <b-form-group label="Отчество">
                        <div class="input-with-icon">
                            <b-form-input
                                id="input-3"
                                v-model="patronymic"
                                type="text"
                                :readonly="!isEditingInput3"
                                :class="{
                                    editable: isEditingInput3,
                                    readonly: !isEditingInput3,
                                }"
                                placeholder=""
                                class="input-with-icon"
                            ></b-form-input>

                            <i
                                class="icon icon-pencil-edit"
                                v-if="showEditIcon3"
                                @click="toggleEditingPatronymic"
                            ></i>
                        </div>
                    </b-form-group>
                    <b-form-group label="">
                        <div class="input-without-icon">
                            <b-form-input
                                id="input-4"
                                v-model="iin"
                                :readonly="!isEditing"
                                :class="{ readonly: !isEditing }"
                                placeholder="ИИН"
                            ></b-form-input>
                        </div>
                    </b-form-group>
                    <b-form-group label="Электронная почта">
                        <div class="input-with-icon">
                            <b-form-input
                                id="input-5"
                                v-model="email"
                                type="text"
                                :readonly="!isEditingInput4"
                                :class="{
                                    editable: isEditingInput4,
                                    readonly: !isEditingInput4,
                                }"
                                placeholder=""
                                class="input-with-icon"
                            ></b-form-input>

                            <i
                                class="icon icon-pencil-edit"
                                v-if="showEditIcon4"
                                @click="toggleEditingEmail"
                            ></i>
                        </div>
                    </b-form-group>
                    <b-form-group label="Телефон">
                        <div class="input-with-icon">
                            <b-form-input
                                id="input-6"
                                v-model="phone"
                                type="text"
                                :readonly="!isEditingInput5"
                                :class="{
                                    editable: isEditingInput5,
                                    readonly: !isEditingInput5,
                                }"
                                placeholder=""
                                class="input-with-icon"
                            ></b-form-input>

                            <i
                                class="icon icon-pencil-edit"
                                v-if="showEditIcon5"
                                @click="toggleEditingPhone"
                            ></i>
                        </div>
                    </b-form-group>

                    <b-button
                        v-if="showSendButtons"
                        variant="primary"
                        class="ml-0 mt-1"
                        @click="sendChange()"
                        >Сохранить изменения</b-button
                    >
                    <b-button
                        v-if="showSendButtons"
                        variant="secondary"
                        class="mt-1"
                        @click="onClearEditingInputs()"
                        >Отменить</b-button
                    >

                    <hr />

                    <b-form-group label="Место работы">
                        <div class="input-without-icon">
                            <b-form-input
                                v-bind:title="workPlace"
                                id="input-7"
                                v-model="workPlace"
                                type="text"
                                :readonly="!isEditing"
                                :class="{ readonly: !isEditing }"
                                placeholder=""
                            ></b-form-input>
                        </div>
                    </b-form-group>
                    <b-form-group label="Уровень бюджета">
                        <div class="input-without-icon">
                            <b-form-input
                                id="input-8"
                                v-model="budgetLevel"
                                type="text"
                                :readonly="!isEditing"
                                :class="{ readonly: !isEditing }"
                                placeholder=""
                            ></b-form-input>
                        </div>
                    </b-form-group>
                </b-col>
                <!-- блок справа  -->
                <b-col class="border-left form-card p-0">
                    <div class="actions-tab">
                        <b-dropdown
                            variant="link"
                            class="info"
                            right
                            toggle-class="text-decoration-none"
                            no-caret
                        >
                            <template #button-content>
                                <div class="btn-download">
                                    <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                        <i class="icon icon-keyboard"></i>
                                    </div>
                                </div>
                            </template>
                            <b-dropdown-text>
                                <div class="info-button">
                                    <span @click="infoRegisterDate"
                                        >Режим «Регистрационные данные и права
                                        доступа»<i class="icon icon-folder"></i
                                    ></span>
                                </div>
                                <div class="info-text">
                                    <p>
                                        Настоящий режим «Регистрационные данные
                                        и права доступа» предназначен для
                                        просмотра и редактирования личных данных
                                        Пользователем, а также для просмотра
                                        присвоенных прав доступа к Системе
                                        Пользователем.
                                    </p>
                                </div>
                            </b-dropdown-text>
                        </b-dropdown>
                    </div>
                    <b-row class="border-bottom">
                        <div class="modals-block">
                            <b-button
                                variant="success"
                                v-b-modal.show-fgr-modal
                                @click="handleButtonClick"
                                class="flex-fill compact-button-text"
                                size="xs"
                            >
                                ФУНКЦИОНАЛЬНЫЕ ГРУППЫ
                                <i class="icon icon-keyboard"></i>
                                <b-modal
                                    id="show-fgr-modal"
                                    ref="modalRef"
                                    size="lg"
                                    scrollable
                                    title="ФУНКЦИОНАЛЬНЫЕ ГРУППЫ"
                                    hide-footer
                                    modal-class="cabinet-modal"
                                    centered
                                    headerClass="p-3 border-bottom-0 text-primary"
                                    titleClass="h6"
                                >
                                    <b-input-group
                                        size="sm"
                                        class="mb-2 search-in-modal"
                                    >
                                        <b-form-input
                                            type="search"
                                            placeholder="Поиск"
                                            v-model="searchFuncGr"
                                        />
                                    </b-input-group>
                                    <div class="cabinet-modal-table">
                                        <table
                                            class="table table-striped table-bordered"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Код</th>
                                                    <th>Наименование</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="item in filteredFuncGrList"
                                                    :key="item.code"
                                                >
                                                    <td>{{ item.code }}</td>
                                                    <td>{{ item.name }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-modal>
                            </b-button>

                            <b-button
                                variant="success"
                                v-b-modal.show-abp-modal
                                @click="handleButtonClick"
                                class="flex-fill compact-button-text"
                                size="xs"
                            >
                                АБП
                                <i class="icon icon-keyboard"></i>
                                <b-modal
                                    id="show-abp-modal"
                                    ref="modalRef"
                                    size="lg"
                                    scrollable
                                    modal-class="cabinet-modal"
                                    centered
                                    title="АДМИНИСТРАТОР БЮДЖЕТНЫХ ПРОГРАММ"
                                    hide-footer
                                    headerClass="p-3 border-bottom-0 text-primary"
                                    titleClass="h6"
                                >
                                    <b-input-group
                                        size="sm"
                                        class="mb-2 search-in-modal"
                                    >
                                        <b-form-input
                                            type="search"
                                            placeholder="Поиск"
                                            v-model="searchAbp"
                                        />
                                    </b-input-group>
                                    <div class="cabinet-modal-table">
                                        <table
                                            class="table table-bordered table-striped"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Код</th>
                                                    <th>Наименование</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="item in filteredAbpList"
                                                    :key="item.code"
                                                >
                                                    <td>{{ item.code }}</td>
                                                    <td>{{ item.name }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-modal>
                            </b-button>
                            <b-button
                                variant="success"
                                v-b-modal.show-region-modal
                                @click="handleButtonClick"
                                class="flex-fill compact-button-text"
                                size="xs"
                            >
                                Регионы бюджета
                                <i class="icon icon-keyboard"></i>
                            </b-button>
                            <b-modal
                                id="show-region-modal"
                                ref="modalRef"
                                size="lg"
                                scrollable
                                centered
                                title="Регионы бюджета"
                                hide-footer
                                headerClass="p-3 border-bottom-0 text-primary"
                                titleClass="h6"
                                modal-class="cabinet-modal with-tabs"
                            >
                                <b-tabs
                                    v-model="activeRegTab"
                                    class="tabs-in-tabs"
                                >
                                    <b-tab
                                        title="РЕГИОНЫ"
                                        :active="activeRegTab === 'РЕГИОНЫ'"
                                        @click="handleRegTabClick('РЕГИОНЫ')"
                                    >
                                        <b-input-group
                                            size="sm"
                                            class="mb-2 search-in-modal"
                                        >
                                            <b-form-input
                                                type="search"
                                                placeholder="Поиск"
                                                v-model="searchRegion"
                                            />
                                        </b-input-group>
                                        <div class="cabinet-modal-table">
                                            <table
                                                class="table table-bordered table-striped"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Код</th>
                                                        <th>Наименование</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="item in filteredBudgetRegList"
                                                        :key="item.id"
                                                    >
                                                        <td>
                                                            {{ item.code }}
                                                        </td>
                                                        <td>
                                                            {{ item.name }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </b-tab>
                                    <b-tab
                                        title="МСУ"
                                        :active="activeRegTab === 'МСУ'"
                                        @click="handleRegTabClick('МСУ')"
                                    >
                                        <b-input-group
                                            size="sm"
                                            class="mb-2 search-in-modal"
                                        >
                                            <b-form-input
                                                type="search"
                                                placeholder="Поиск"
                                                v-model="searchMsu"
                                            />
                                        </b-input-group>
                                        <div class="cabinet-modal-table">
                                            <table
                                                class="table table-bordered table-striped"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Код</th>
                                                        <th>Наименование</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="item in filteredMsuList"
                                                        :key="item.id"
                                                    >
                                                        <td>
                                                            {{ item.code }}
                                                        </td>
                                                        <td>
                                                            {{ item.name }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-modal>

                            <b-button
                                variant="success"
                                v-b-modal.show-gu-modal
                                @click="handleButtonClick"
                                class="flex-fill compact-button-text"
                                size="xs"
                            >
                                Учреждения
                                <i class="icon icon-keyboard"></i>
                                <b-modal
                                    id="show-gu-modal"
                                    ref="modalRef"
                                    size="lg"
                                    scrollable
                                    title="УЧРЕЖДЕНИЯ"
                                    hide-footer
                                    headerClass="p-3 border-bottom-0 text-primary"
                                    titleClass="h6"
                                    centered
                                    modal-class="cabinet-modal with-tabs"
                                >
                                    <b-tabs
                                        v-model="activeTab"
                                        class="tabs-in-tabs"
                                    >
                                        <b-tab
                                            title="ГУ"
                                            :active="activeTab === 'ГУ'"
                                            @click="handleTabClick('ГУ')"
                                        >
                                            <b-input-group
                                                size="sm"
                                                class="mb-2 search-in-modal"
                                            >
                                                <b-form-input
                                                    type="search"
                                                    placeholder="Поиск"
                                                    v-model="searchGu"
                                                />
                                            </b-input-group>
                                            <div class="cabinet-modal-table">
                                                <table
                                                    class="table table-bordered table-striped"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>Код</th>
                                                            <th>
                                                                Наименование
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="item in filteredGuList"
                                                            :key="item.code"
                                                        >
                                                            <td>
                                                                {{ item.code }}
                                                            </td>
                                                            <td>
                                                                {{ item.name }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </b-tab>
                                        <b-tab
                                            title="ГККП"
                                            :active="activeTab === 'ГККП'"
                                            @click="handleTabClick('ГККП')"
                                        >
                                            <b-input-group
                                                size="sm"
                                                class="mb-2 search-in-modal"
                                            >
                                                <b-form-input
                                                    type="search"
                                                    placeholder="Поиск"
                                                    v-model="searchKgkp"
                                                />
                                            </b-input-group>
                                            <div class="cabinet-modal-table">
                                                <table
                                                    class="table table-bordered table-striped"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>БИН</th>
                                                            <th>
                                                                Наименование
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="item in filteredKgkpList"
                                                            :key="item.bin"
                                                        >
                                                            <td>
                                                                {{ item.bin }}
                                                            </td>
                                                            <td>
                                                                {{ item.name }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </b-tab>
                                    </b-tabs>
                                </b-modal>
                            </b-button>
                            <b-button
                                variant="success"
                                v-b-modal.show-income-modal
                                @click="handleButtonClick"
                                class="flex-fill compact-button-text"
                                size="xs"
                            >
                                Категория доходов
                                <i class="icon icon-keyboard"></i>
                                <b-modal
                                    id="show-income-modal"
                                    ref="modalRef"
                                    size="lg"
                                    scrollable
                                    title="КАТЕГОРИЯ ДОХОДОВ"
                                    hide-footer
                                    headerClass="p-3 border-bottom-0 text-primary"
                                    titleClass="h6"
                                    centered
                                    modal-class="cabinet-modal"
                                >
                                    <b-input-group
                                        size="sm"
                                        class="mb-2 search-in-modal"
                                    >
                                        <b-form-input
                                            type="search"
                                            placeholder="Поиск"
                                            v-model="searchCategory"
                                        />
                                    </b-input-group>
                                    <div class="cabinet-modal-table">
                                        <table
                                            class="table table-bordered table-striped"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Код</th>
                                                    <th>Наименование</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="item in filteredCategory"
                                                    :key="item.code"
                                                >
                                                    <td>{{ item.code }}</td>
                                                    <td>{{ item.name }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-modal>
                            </b-button>

                            <b-button
                                variant="success"
                                v-b-modal.show-snp-kato-modal
                                @click="handleButtonClick"
                                class="flex-fill compact-button-text"
                                size="xs"
                            >
                                СНП/СО/РАЙОН/ОБЛАСТЬ
                                <i class="icon icon-keyboard"></i>
                                <b-modal
                                    id="show-snp-kato-modal"
                                    ref="modalRef"
                                    size="lg"
                                    scrollable
                                    title="СНП/СО/РАЙОН/ОБЛАСТЬ"
                                    hide-footer
                                    headerClass="p-3 border-bottom-0 text-primary"
                                    titleClass="h6"
                                    centered
                                    modal-class="cabinet-modal"
                                >
                                    <b-input-group
                                        size="sm"
                                        class="mb-2 search-in-modal"
                                    >
                                        <b-form-input
                                            type="search"
                                            placeholder="Поиск"
                                            v-model="searchSNP"
                                        />
                                    </b-input-group>
                                    <div class="cabinet-modal-table">
                                        <table
                                            class="table table-bordered table-striped"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Код</th>
                                                    <th>Наименование</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="item in filteredSNPList"
                                                    :key="item.code"
                                                >
                                                    <td>{{ item.code }}</td>
                                                    <td>{{ item.name }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-modal>
                            </b-button>
                        </div>
                    </b-row>
                    <p class="label-style bold black py-2 px-3 m-0">МОДУЛИ</p>
                    <div class="modules-block table-container">
                        <b-table-simple class="table table-striped" small>
                            <b-thead>
                                <b-tr class="module-data-header">
                                    <b-th>Код</b-th>
                                    <b-th>Модуль</b-th>
                                    <b-th>Уровень доступа</b-th>
                                    <b-th>Уровень полномочий</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tr
                                v-for="item in modulesList"
                                :key="item.code"
                                class="module-data"
                            >
                                <b-td>{{ item.code }} </b-td>
                                <b-td>{{ item.name }} </b-td>
                                <b-td
                                    ><div class="radio-buttons">
                                        <input
                                            type="radio"
                                            v-model="item.access_level"
                                            :value="1"
                                            :checked="item.access_level === 1"
                                            :disabled="true"
                                        />
                                        <span>Просмотр</span>
                                    </div>
                                    <div class="radio-buttons">
                                        <input
                                            type="radio"
                                            v-model="item.access_level"
                                            :value="2"
                                            :checked="item.access_level === 2"
                                            :disabled="true"
                                        />
                                        <span>Редактирование</span>
                                    </div></b-td
                                >

                                <b-td
                                    ><ul>
                                        <li
                                            v-for="operation in item.operation"
                                            :key="operation"
                                        >
                                            {{ operation }}
                                        </li>
                                    </ul></b-td
                                >
                            </b-tr>
                        </b-table-simple>
                    </div>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import CabinetHeader from "./CabinetHeader";
import axios from "axios";
import moment from "moment";

export default {
    name: "UserDetails",
    components: { CabinetHeader },
    data() {
        return {
            username: null,
            oblKato: null,
            firstName: null,
            lastName: null,
            patronymic: null,
            iin: "ИИН  " + "",
            email: null,
            phone: "+7" + "" + "",
            workPlace: "",
            workPlaceGu: "",
            workPlaceKgkp: "",
            activeTab: 0,
            activeRegTab: 0,
            searchFuncGr: "",
            searchAbp: "",
            searchRegion: "",
            searchMsu: "",
            searchGu: "",
            searchKgkp: "",
            searchCategory: "",
            searchSNP: "",
            budgetLevelArr: [],
            budgetLevel: "",
            userLevels: [],
            accessSnp: [],
            modalForm: false,
            regionsSNP: [
                {
                    key: "code",
                    label: "Код",
                    sortable: false,
                },
                { key: "name", label: "Наименование", sortable: true },
            ],
            budgetRegionList: [
                {
                    key: "code",
                    label: "Код",
                    sortable: true,
                },
                { key: "name", label: "Наименование", sortable: false },
            ],
            msuList: [
                {
                    key: "code",
                    label: "Код",
                    sortable: true,
                },
                { key: "name", label: "Наименование", sortable: false },
            ],
            funcGrList: [
                {
                    key: "code",
                    label: "Код",
                    sortable: true,
                },
                { key: "name", label: "Наименование", sortable: false },
            ],
            abpList: [
                {
                    key: "code",
                    label: "Код",
                    sortable: false,
                },
                { key: "name", label: "Наименование", sortable: true },
            ],
            guList: [
                {
                    key: "code",
                    label: "Код",
                    sortable: false,
                },
                { key: "name", label: "Наименование", sortable: true },
            ],
            kgkpList: [
                {
                    key: "bin",
                    label: "БИН",
                    sortable: false,
                },
                { key: "name", label: "Наименование", sortable: true },
            ],
            categoryList: [
                {
                    key: "code",
                    label: "Код",
                    sortable: false,
                },
                { key: "name", label: "Наименование", sortable: true },
            ],
            access_level: [
                { value: 1, text: "Просмотр" },
                { value: 2, text: "Редактирование" },
                { value: 3, text: "Админ" },
            ],
            modulesList: [
                {
                    key: "code",
                    label: "Код",
                    sortable: true,
                },
                { key: "name", label: "Наименование", sortable: false },
                {
                    key: "access_level",
                    label: "Уровень доступа",
                    sortable: false,
                },
                {
                    key: "operation",
                    label: "Уровень полномочий",
                    sortable: false,
                },
            ],
            editData: {},
            showEditIcon1: true,
            showEditIcon2: true,
            showEditIcon3: true,
            showEditIcon4: true,
            showEditIcon5: true,
            isEditing: false,
            isEditingInput1: false,
            isEditingInput2: false,
            isEditingInput3: false,
            isEditingInput4: false,
            isEditingInput5: false,
            showSendButtons: false,
        };
    },
    async mounted() {
        try {
            await Promise.all([
                this.getUserInfo(),
                this.getUserWorkplace(),
                this.loadUserLevels(),
                this.getKatoNameByCode(),
                this.getFuncGroupByCode(),
                this.getAbpByCode(),
                this.getUserGU(),
                this.getCategByCode(),
                this.getUserBudgetRegions(),
                this.getUserModules(),
                this.getModulesByCode(),
                this.getSnpRoles(),
                this.getUserOperations(),
            ]);
        } catch (error) {
            this.handleError("Ошибка при загрузке данных", error);
        }
    },

    computed: {
        filteredFuncGrList() {
            if (!this.searchFuncGr) {
                return this.funcGrList;
            } else {
                const query = this.searchFuncGr.toLowerCase();
                return this.funcGrList.filter((item) => {
                    return (
                        item.code.toString().toLowerCase().includes(query) ||
                        item.name.toLowerCase().includes(query)
                    );
                });
            }
        },
        filteredAbpList() {
            if (!this.searchAbp) {
                return this.abpList;
            } else {
                const query = this.searchAbp.toLowerCase();
                return this.abpList.filter((item) => {
                    return (
                        item.code.toString().toLowerCase().includes(query) ||
                        item.name.toLowerCase().includes(query)
                    );
                });
            }
        },
        filteredSNPList() {
            if (!this.searchSNP) {
                return this.regionsSNP;
            } else {
                const query = this.searchSNP.toLowerCase();
                return this.regionsSNP.filter((item) => {
                    return (
                        item.code.toString().toLowerCase().includes(query) ||
                        item.name.toLowerCase().includes(query)
                    );
                });
            }
        },
        filteredBudgetRegList() {
            if (!this.searchRegion) {
                return this.budgetRegionList;
            } else {
                const query = this.searchRegion.toLowerCase();
                return this.budgetRegionList.filter((item) => {
                    return (
                        item.code.toString().toLowerCase().includes(query) ||
                        item.name.toLowerCase().includes(query)
                    );
                });
            }
        },
        filteredMsuList() {
            if (!this.searchMsu) {
                return this.msuList;
            } else {
                const query = this.searchMsu.toLowerCase();
                return this.msuList.filter((item) => {
                    return (
                        item.code.toString().toLowerCase().includes(query) ||
                        item.name.toLowerCase().includes(query)
                    );
                });
            }
        },
        filteredGuList() {
            if (!this.searchGu) {
                return this.guList;
            } else {
                const query = this.searchGu.toLowerCase();
                return this.guList.filter((item) => {
                    return (
                        item.code.toString().toLowerCase().includes(query) ||
                        item.name.toLowerCase().includes(query)
                    );
                });
            }
        },
        filteredKgkpList() {
            if (!this.searchKgkp) {
                return this.kgkpList;
            } else {
                const query = this.searchKgkp.toLowerCase();
                return this.kgkpList.filter((item) => {
                    return (
                        item.bin.toString().toLowerCase().includes(query) ||
                        item.name.toLowerCase().includes(query)
                    );
                });
            }
        },
        filteredCategory() {
            if (!this.searchCategory) {
                return this.categoryList;
            } else {
                const query = this.searchCategory.toLowerCase();
                return this.categoryList.filter((item) => {
                    return (
                        item.code.toString().toLowerCase().includes(query) ||
                        item.name.toLowerCase().includes(query)
                    );
                });
            }
        },
    },

    methods: {
        async infoRegisterDate() {
            if (this.isLoad) {
                this.makeToast(
                    "info",
                    "Внимание",
                    "Скоро начнется скачивание. Пожалуйста ожидайте."
                );
            } else {
                this.isLoad = true;
                await axios({
                    method: "post",
                    url: "/api-py/get-info/35",
                    responseType: "blob",
                    data: null,
                })
                    .then((response) => response.data)
                    .then((data) => {
                        const url = window.URL.createObjectURL(
                            new Blob([data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        const titleOfFile =
                            "Регистрационные_данные_и_права доступа";
                        link.setAttribute("download", titleOfFile + ".pdf");
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.makeToast(
                            "danger",
                            "Ошибка запроса infoRegisterDate()",
                            error.toString()
                        );
                    })
                    .finally(() => {
                        this.isLoad = false;
                    });
            }
        },
        async getUserInfo() {
            try {
                const response = await fetch(
                    "/api-py/get-realm-user/" + this.$store.getters.user_uuid
                );
                const data = await response.json();
                this.username = data.username;
                this.lastName = data.lastName;
                const fullName = data.firstName;
                const nameParts = fullName.split(" "); // Разбиваем поле имя на 2 части
                this.firstName = nameParts[0]; // Первая часть будет Именем
                this.patronymic = nameParts.slice(1).join(" "); // Оставшаяся часть будет Отчеством, так как нет поля Отчества
                if (data.username.startsWith("iin")) {
                    this.iin = "ИИН " + data.username.substring(3);
                } else {
                    this.iin;
                }

                this.email = data.email;

                if (
                    data.attributes &&
                    data.attributes.PHONE &&
                    data.attributes.PHONE.length > 0
                ) {
                    this.phone = data.attributes.PHONE[0];
                } else {
                    this.phone;
                }
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса getUserInfo",
                    error.toString()
                );
            }
        },
        async getUserGuWork() {
            try {
                const response = await fetch(
                    "/api-py/user-workplace/" + this.$store.getters.user_uuid
                );
                const code = await response.json();
                if (code && code.length > 0) {
                    this.workPlaceGu = code[0].workplace;
                } else {
                    this.makeToast("danger", "Рабочее место не найдено");
                }
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка загрузки место работы для : " +
                        this.$store.getters.user_uuid,
                    error.toString()
                );
            }
        },
        async getUserKGKPWork() {
            try {
                const response = await fetch(
                    "/api-py/user-workplace-kgkp/" +
                        this.$store.getters.user_uuid
                );
                const data = await response.json();
                if (data && data.length > 0 && data[0].workplace) {
                    this.workPlaceKgkp = data[0].workplace;
                } else {
                    this.makeToast("danger", "Рабочее место не найдено");
                }
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса места работы для : " +
                        this.$store.getters.user_uuid,
                    error.toString()
                );
            }
        },

        async getUserWorkplace() {
            try {
                const [guCodeResponse, kgkpDataResponse] = await Promise.all([
                    fetch(
                        "/api-py/user-workplace/" +
                            this.$store.getters.user_uuid
                    ),
                    fetch(
                        "/api-py/user-workplace-kgkp/" +
                            this.$store.getters.user_uuid
                    ),
                ]);

                const guCode = await guCodeResponse.json();
                const kgkpData = await kgkpDataResponse.json();

                if (guCode && guCode.length > 0 && guCode[0].workplace) {
                    this.workPlaceGu = guCode[0].workplace;
                    const res = await fetch(
                        "/api-py/dict-gu-by-code/" + this.workPlaceGu
                    );
                    const guData = await res.json();
                    this.workPlace =
                        this.workPlaceGu +
                        "-" +
                        (guData && guData.length > 0 ? guData[0].name_ru : "");
                } else if (
                    kgkpData &&
                    kgkpData.length > 0 &&
                    kgkpData[0].workplace
                ) {
                    this.workPlaceKgkp = kgkpData[0].workplace;
                    const kgkpDataDetails = await this.getKGKPNameByCode(
                        this.workPlaceKgkp
                    );
                    this.workPlace =
                        this.workPlaceKgkp +
                        "-" +
                        (kgkpDataDetails && kgkpDataDetails.length > 0
                            ? kgkpDataDetails[0].name_ru
                            : "");
                } else {
                    this.makeToast("danger", "Рабочее место не найдено");
                }
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка загрузки данных места работы для: " +
                        this.$store.getters.user_uuid,
                    error.toString()
                );
            }
        },
        async loadUserLevels() {
            try {
                this.budgetLevelArr = [];

                const response = await fetch(
                    "/api-py/get-user-budget-level-only/" +
                        this.$store.getters.user_uuid
                );
                const levels = await response.json();

                if (levels.includes("2")) {
                    this.budgetLevelArr.push("2-областной");
                }
                if (levels.includes("3")) {
                    this.budgetLevelArr.push("3-районный");
                }
                if (levels.includes("4")) {
                    this.budgetLevelArr.push("4-МСУ");
                }
                this.budgetLevel = this.budgetLevelArr.join(", ");
                this.userLevels = JSON.stringify(levels);
            } catch (error) {
                this.handleError("Ошибка запроса уровней бюджета", error);
            }
        },
        async getObl() {
            try {
                await fetch(
                    "/api-py/get-budget-obl/" + this.$store.state._instanceCode
                )
                    .then((response) => response.json())
                    .then((json) => {
                        this.oblKato = json.obl;
                    });
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса getObl",
                    error.toString()
                );
            }
        },
        async getSnpKato() {
            try {
                const response = await fetch(
                    "/api-py/user-kato/" + this.$store.getters.user_uuid
                );
                const data = await response.json();
                if (data && data.length > 0) {
                    this.regionsSNP = data.map((item) => ({
                        code: item.kato,
                        name: item.kato,
                    }));
                } else {
                    this.regionsSNP = [];
                }
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса GetSnpInfo",
                    error.toString()
                );
            }
        },

        async getKatoNameByCode() {
            try {
                await this.getSnpKato();
                await this.getObl();

                const response = await fetch(
                    "/api-py/get-katos-by-obl/" + this.oblKato
                );
                const data = await response.json();

                for (const regionSNP of this.regionsSNP) {
                    const foundRegion = data.find(
                        (region) => region.code === regionSNP.code
                    );
                    if (foundRegion) {
                        regionSNP.name = foundRegion.name_ru;
                    }
                }
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка загрузки данных регионов по : " +
                        this.$store.getters.user_uuid,
                    error.toString()
                );
            }
        },
        async getUserFuncGroup() {
            try {
                const response = await fetch(
                    "/api-py/user-fgr/" + this.$store.getters.user_uuid
                );
                const data = await response.json();
                if (data && data.length > 0) {
                    this.funcGrList = data.map((item) => ({
                        code: item.fgr,
                        name: item.fgr,
                    }));
                } else {
                    this.funcGrList = [];
                }
                this.funcGrList.sort((a, b) => a.code - b.code);
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса Функциональной группы",
                    error.toString()
                );
            }
        },
        async getFuncGroupByCode() {
            try {
                await this.getUserFuncGroup();
                const response = await fetch(
                    "/api-py/get-dict-func-childs-by-parent/0"
                );
                const data = await response.json();
                for (const fgr of this.funcGrList) {
                    const foundGr = data.find((item) => item.gr === fgr.code);
                    if (foundGr) {
                        fgr.name = foundGr.name_ru;
                    }
                }
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка загрузки ФГР : " + this.$store.getters.user_uuid,
                    error.toString()
                );
            }
        },
        async getUserAbp() {
            try {
                const response = await fetch(
                    "/api-py/user-abp/" + this.$store.getters.user_uuid
                );
                const data = await response.json();
                if (data && data.length > 0) {
                    this.abpList = data.map((item) => ({
                        code: item.abp,
                        name: "",
                    }));
                } else {
                    this.abpList = [];
                }
                this.abpList.sort((a, b) => a.code - b.code);
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса АБП",
                    error.toString()
                );
            }
        },

        async getAbpByCode() {
            try {
                await this.getUserAbp();
                const response = await fetch(
                    "/api-py/get-dict-ved-abp-by-budgetlevel/" + "[2,3,4]"
                );
                const allAbpData = await response.json();
                for (const abp of this.abpList) {
                    const foundAbp = allAbpData.find(
                        (item) => item.abp === abp.code
                    );
                    if (foundAbp) {
                        abp.name = foundAbp.name_ru;
                    }
                }
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка загрузки АБП для : " +
                        this.$store.getters.user_uuid,
                    error.toString()
                );
            }
        },
        async getUserCateg() {
            try {
                const response = await fetch(
                    "/api-py/user-kat/" + this.$store.getters.user_uuid
                );
                const data = await response.json();
                if (data && data.length > 0) {
                    this.categoryList = data.map((item) => ({
                        code: item.kat,
                        name: item.kat,
                    }));
                } else {
                    this.categoryList = [];
                }
                this.categoryList.sort((a, b) => a.code - b.code);
            } catch (error) {
                this.handleError("Ошибка запроса Категории доходов", error);
            }
        },
        async getCategByCode() {
            try {
                await this.getUserCateg();
                const response = await fetch(
                    "/api-py/get-dict-doh-childs-by-parent/0"
                );
                const data = await response.json();
                for (const kat of this.categoryList) {
                    const foundKat = data.find((item) => item.kat === kat.code);
                    if (foundKat) {
                        kat.name = foundKat.name_ru;
                    }
                }
            } catch (error) {
                this.handleError(
                    "Ошибка загрузки доступных категории доходов",
                    error
                );
            }
        },
        async handleRegTabClick(tabName) {
            if (tabName === "РЕГИОНЫ") {
                this.activeRegTab = 0;
                await this.getUserBudgetRegions();
            } else if (tabName === "МСУ") {
                this.activeRegTab = 1;
                await this.getUserMsu();
            }
        },

        async getUserBudgetRegions() {
            try {
                await this.getObl();
                this.budgetRegionList = [];

                const response = await fetch(
                    "/api-py/user-region/" + this.$store.getters.user_uuid
                );
                const userRegions = await response.json();

                const allRegionsResponse = await fetch(
                    "/api-py/get-regions-by-obl/" + this.oblKato
                );
                const allRegions = await allRegionsResponse.json();

                for (const userRegion of userRegions) {
                    const foundRegion = allRegions.find(
                        (region) => region.code === userRegion.region
                    );
                    if (foundRegion) {
                        this.budgetRegionList.push({
                            code: foundRegion.code,
                            name: foundRegion.name_ru,
                        });
                    }
                }
                this.budgetRegionList.sort((a, b) => a.code - b.code);
            } catch (error) {
                this.handleError("Ошибка загрузки доступных Регионов", error);
            }
        },
        async getMsuNameByCode(code) {
            const msuResponse = await fetch(
                "/api-py/dict-region-by-code/" + code
            );
            const msuData = await msuResponse.json();
            return msuData;
        },

        async getUserMsu() {
            try {
                this.msuList = [];
                const response = await fetch(
                    "/api-py/user-msu/" + this.$store.getters.user_uuid
                );
                const data = await response.json();
                const msuPromises = data.map(async (item) => {
                    if (!item.region.endsWith("01")) {
                        const msuData = await this.getMsuNameByCode(
                            item.region
                        );
                        return msuData.find((t) => t.code === item.region);
                    }
                });
                const msuResults = await Promise.all(msuPromises);
                msuResults.filter(Boolean).forEach((msu) => {
                    this.msuList.push({
                        code: msu.code,
                        name: msu.name_ru,
                    });
                });

                this.msuList.sort((a, b) => a.code - b.code);
            } catch (error) {
                console.error("Ошибка при загрузке данных:", error);
                this.handleError(
                    "Ошибка загрузки доступных МСУ",
                    error.message
                );
            }
        },
        async handleTabClick(tabName) {
            if (tabName === "ГУ") {
                this.activeTab = 0;
                await this.getUserGU();
            } else if (tabName === "ГККП") {
                this.activeTab = 1;
                await this.getUserKGKP();
            }
        },
        async getUserGU() {
            try {
                const response = await fetch(
                    "/api-py/dict-gu-by-userid/" + this.$store.getters.user_uuid
                );
                const data = await response.json();
                if (data && data.length > 0) {
                    this.guList = data.map((item) => ({
                        code: item.code,
                        name: item.name_ru,
                    }));
                } else {
                    this.guList = [];
                }
                this.guList.sort((a, b) => a.code - b.code);
            } catch (error) {
                this.handleError("Ошибка загрузки доступных ГУ", error);
            }
        },

        async getUserKGKP() {
            try {
                this.kgkpList = [];
                const response = await fetch(
                    "/api-py/dict-kgkp-by-userid/" +
                        this.$store.getters.user_uuid +
                        "/" +
                        moment(this.DateFrom).format("YYYY-MM-DD")
                );
                const data = await response.json();
                if (data && data.length > 0) {
                    this.kgkpList = data.map((item) => ({
                        bin: item.bin,
                        name: item.name_ru,
                    }));
                } else {
                    this.kgkpList = [];
                }

                const secondRes = await fetch(
                    "/api-py/user-kgkp/" + this.$store.getters.user_uuid
                );
                const secondData = await secondRes.json();

                // Добавляем уникальные элементы из secondData в this.kgkpList, если они отсутствуют в первом сервиса
                for (const item of secondData) {
                    const existsInFirstData = data.some(
                        (el) => el.bin === item.kgkp
                    );
                    if (!existsInFirstData) {
                        const kgkpNameData = await this.getKGKPNameByCode(
                            item.kgkp
                        );

                        this.kgkpList.push({
                            bin: item.kgkp,
                            name: kgkpNameData[0].name_ru,
                        });
                    }
                }

                this.kgkpList.sort((a, b) => a.bin - b.bin);
            } catch (error) {
                this.handleError("Ошибка загрузки доступных ГККП", error);
            }
        },

        async getKGKPNameByCode(code) {
            const res = await fetch("/api-py/dict-kgkp-by-code/" + code);
            const data = await res.json();
            return data;
        },

        async getUserModules() {
            try {
                const response = await fetch(
                    "/api/um/module/links/" + this.$store.getters.user_uuid
                );
                const data = await response.json();

                this.modulesList = data.map((item) => ({
                    code: item.modules,
                    name: item.modules,
                    access_level: item.accessLevel,
                    operation: item.operations ? item.operations : [],
                }));
                this.modulesList.sort((a, b) => a.code.localeCompare(b.code));
            } catch (error) {
                this.handleError("Ошибка загрузки Модулей", error);
            }
        },

        async getModulesByCode() {
            try {
                const response = await fetch("/api-py/sic-modules");
                const data = await response.json();

                this.modulesList.forEach((moduleFromFirstService) => {
                    const foundModule = data.find(
                        (moduleFromSecondService) =>
                            moduleFromSecondService.code ===
                            moduleFromFirstService.code
                    );

                    if (foundModule && foundModule.name_ru) {
                        moduleFromFirstService.name = foundModule.name_ru;
                    }
                });
            } catch (error) {
                this.handleError("Ошибка загрузки Модулей", error);
            }
        },

        async getSnpRoles() {
            try {
                this.accessSnp = [];
                const userRoles = this.$store.getters.user.roles.map((role) =>
                    role.toLowerCase()
                );
                if (userRoles.includes("usersnp_region_departments")) {
                    this.accessSnp.push(
                        "Первичное утверждение/отправление на доработку (Область)"
                    );
                }
                if (userRoles.includes("role_snp_region_view")) {
                    this.accessSnp.push(
                        "Финальное утверждение/отправление на доработку (Область)"
                    );
                }
                if (userRoles.includes("usersnp_district_departments")) {
                    this.accessSnp.push(
                        "Первичное согласование/отправление на доработку (Район)"
                    );
                }
                if (
                    userRoles.includes("role_snp_district_edit") ||
                    userRoles.includes("role_snp_district_view")
                ) {
                    this.accessSnp.push(
                        "Финальное согласование/отправление на доработку (Район)"
                    );
                }
                if (
                    userRoles.includes("role_snp_village_edit") ||
                    userRoles.includes("role_snp_village_view")
                ) {
                    this.accessSnp.push(
                        "Заполнение/отправление на согласование (Сельский округ)"
                    );
                }
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса GetSnpOperations",
                    error.toString()
                );
            }
        },
        async getUserOperations() {
            try {
                const response = await fetch("/api-py/dict-user-orerations");
                const data = await response.json();
                for (const module of this.modulesList) {
                    const operations = [];
                    for (const operCode of module.operation) {
                        const foundOper = data.find(
                            (item) => item.code === operCode
                        );
                        if (foundOper) {
                            operations.push(foundOper.name_ru);
                        }
                    }
                    if (module.code === "003.003") {
                        operations.push(...this.accessSnp);
                    }
                    module.operation = operations;
                }
            } catch (error) {
                this.handleError("Ошибка загрузки Операции", error);
            }
        },
        async sendChange() {
            this.editedData = {
                id: this.$store.getters.user_uuid,
                user_name: this.$store.getters.user.preferred_username,
            };

            if (this.isFieldEdited("firstName")) {
                if (this.firstName.trim() === "") {
                    this.makeToast(
                        "warning",
                        "Имя не может быть пустым!",
                        "Уведомление"
                    );
                } else {
                    this.editedData.first_name = this.firstName;
                }
            }
            if (this.isFieldEdited("lastName")) {
                if (this.lastName.trim() === "") {
                    this.makeToast(
                        "warning",
                        "Фамилия не может быть пустым!",
                        "Уведомление"
                    );
                } else {
                    this.editedData.last_name = this.lastName;
                }
            }
            if (this.isFieldEdited("patronymic")) {
                this.editedData.middle_name = this.patronymic;
            }
            if (this.isFieldEdited("email")) {
                this.editedData.email = this.email;
            }
            if (this.isFieldEdited("phone")) {
                this.editedData.phone = this.phone;
            }

            if (Object.keys(this.editedData).length > 2) {
                try {
                    const response = await axios.post(
                        "/api-py/update-user-profile",
                        this.editedData
                    );
                    if (response.status === 200) {
                        this.makeToast(
                            "success",
                            "Данные обновлены",
                            "Сообщение"
                        );
                    } else {
                        this.makeToast(
                            "danger",
                            "Ошибка сохранения данных",
                            "Ошибка"
                        );
                    }
                } catch (error) {
                    this.handleError("Ошибка сохранения!", error);
                }
            } else if (Object.keys(this.editedData).length === 2) {
                this.makeToast(
                    "warning",
                    "Нет измененных данных!",
                    "Уведомление"
                );
            } else {
                this.makeToast(
                    "warning",
                    "Нет данных для отправки!",
                    "Уведомление"
                );
            }
            this.showSendButtons = false;
            this.showEditIcon1 = true;
            this.showEditIcon2 = true;
            this.showEditIcon3 = true;
            this.showEditIcon4 = true;
            this.showEditIcon5 = true;
            this.isEditingInput1 = false;
            this.isEditingInput2 = false;
            this.isEditingInput3 = false;
            this.isEditingInput4 = false;
            this.isEditingInput5 = false;
            await this.getUserInfo();
        },

        isFieldEdited(fieldName) {
            if (this.editData.hasOwnProperty(fieldName)) {
                return this.editData[fieldName] !== this[fieldName];
            } else {
                return false;
            }
        },

        handleError(message, error) {
            this.makeToast(
                "danger",
                message + ": " + this.$store.getters.user.preferred_username,
                error.toString()
            );
        },

        toggleEditingLastName() {
            this.isEditingInput1 = !this.isEditingInput1;
            this.showEditIcon1 = !this.showEditIcon1;
            this.editData = { ...this.editData, lastName: this.lastName };
            this.showSendButtons = true;
        },
        toggleEditingFirstName() {
            this.isEditingInput2 = !this.isEditingInput2;
            this.showEditIcon2 = !this.showEditIcon2;
            this.editData = { ...this.editData, firstName: this.firstName };
            this.showSendButtons = true;
        },
        toggleEditingPatronymic() {
            this.isEditingInput3 = !this.isEditingInput3;
            this.showEditIcon3 = !this.showEditIcon3;
            this.editData = { ...this.editData, patronymic: this.patronymic };
            this.showSendButtons = true;
        },
        toggleEditingEmail() {
            this.isEditingInput4 = !this.isEditingInput4;
            this.showEditIcon4 = !this.showEditIcon4;
            this.editData = { ...this.editData, email: this.email };
            this.showSendButtons = true;
        },
        toggleEditingPhone() {
            this.isEditingInput5 = !this.isEditingInput5;
            this.showEditIcon5 = !this.showEditIcon5;
            this.editData = { ...this.editData, phone: this.phone };
            this.showSendButtons = true;
        },

        async onClearEditingInputs() {
            try {
                await this.getUserInfo();
                this.showSendButtons = false;
                this.showEditIcon1 = true;
                this.showEditIcon2 = true;
                this.showEditIcon3 = true;
                this.showEditIcon4 = true;
                this.showEditIcon5 = true;
                this.isEditingInput1 = false;
                this.isEditingInput2 = false;
                this.isEditingInput3 = false;
                this.isEditingInput4 = false;
                this.isEditingInput5 = false;
            } catch (error) {
                this.makeToast(
                    "danger",
                    "Ошибка при отмене редактирования",
                    error.toString()
                );
            }
        },
        showModal() {
            this.modalForm = true;
        },
        handleButtonClick() {
            this.showModal(false);
            this.searchFuncGr = "";
            this.searchAbp = "";
            this.searchRegion = "";
            this.searchMsu = "";
            this.searchGu = "";
            this.searchKgkp = "";
            this.searchCategory = "";
            this.searchSNP = "";
        },
        makeToast(variant = null, tostbody, title) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: "b-toaster-top-center",
                autoHideDelay: 2000,
                solid: true,
                appendToast: true,
            });
        },
    },
};
</script>

<style scoped>
.table-striped tr:nth-child(even) {
    background-color: #eef1f8;
}
.modules-block {
    background-color: #fff;
}
.module-data-header {
    font-size: 15px;
    font-weight: bold;
}
.module-data {
    font-size: 14px;
    font-weight: bolder;
}
.modals-block {
    padding: 10px 20px;
    margin-top: 25px;
}
.actions-tab {
    position: absolute;
    top: 2px;
    right: 0;
}
.info-button span {
    color: #c45a56;
}
.compact-button-text {
    font-size: small;
    padding: 15px;
}
.input-with-icon {
    position: relative;
    margin-top: 7px;
}
.input-without-icon {
    margin-top: 7px;
}
.icon-pencil-edit {
    position: absolute;
    color: rgb(0, 183, 255);
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}
.title {
    color: rgb(39, 141, 182);
    font-size: 14px;
    font-weight: bolder;
    text-transform: uppercase;
}
.status {
    color: rgb(218, 158, 46);
    text-decoration: underline;
    margin-left: 10px;
}
.icon-keyboard {
    padding-bottom: 10px;
    transform: rotate(90deg);
}

.editable {
    background-color: #fff;
}
.readonly {
    background-color: #eef1f8;
}
.radio-buttons input[type="radio"] {
    display: none;
}
.radio-buttons span {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    line-height: 22px;
    white-space: nowrap;
}
.radio-buttons input[type="radio"] + span:before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 4px;
    margin-bottom: -3px;
    border-radius: 50%;
    border: 2px solid #dee3e9;
    background-color: #fff;
}
.radio-buttons input[type="radio"]:checked + span:before {
    background-color: #5b8ff9;
    border: 2px solid #5b8ff9;
    background-clip: content-box;
    padding: 3px;
}

.cabinet-modal .cabinet-modal-table::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: #f5f5f5;
}

.cabinet-modal .cabinet-modal-table::-webkit-scrollbar-thumb {
    height: 80px;
    border-radius: 10px;
}

.user-details .table-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #f5f5f5;
}

.user-details .table-container::-webkit-scrollbar-thumb {
    height: 180px;
    border-radius: 10px;
}

.search-in-modal:before {
    content: "\ea32";
    font: normal normal normal 12px/1 "Nucleo";
    position: absolute;
    top: 10px;
    right: 10px;
    color: #7c9db3;
    z-index: 3;
    border-color: #ffffff;
}

@media (max-width: 1780px) {
    .btn {
        margin-top: 5px;
    }
}
</style>
